/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useOutlet,
} from "react-router-dom";
import ErrorPage from "./error-page";
import News from "./pages/news";
import Community from "./pages/community";
import Nations from "./pages/nations/nationsPage";
import TradePage from "./pages/trade";
import DevelopmentPage from "./pages/development";
import Diplomacy from "./pages/diplomacy";
import { CssBaseline } from "@mui/material";
import ArticlePage from "./pages/news/articlePage";
import NationPage from "./pages/nations/nationPage";
import ProfilePage from "./pages/profile/profilePage";
import UserContext from "./components/UserContext";
import Landing from "./pages/landing";
import { DevSupport } from "@react-buddy/ide-toolbox";

import * as Sentry from "@sentry/react";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { SnackbarProvider } from "notistack";
import theme from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import MapPage from "./pages/mapPage";
import About from "./pages/about";
import Contact from "./pages/contact";
import SignInPage from "./pages/signInPage";
import SignUpPage from "./pages/signUpPage";
import ResetPage from "./pages/reset";
import ConfirmPage from "./pages/confirmPage";
import { Middleware, mutate, SWRConfig } from "swr";
import Header from "./components/header";
import LandingHeader from "./components/LandingHeader";
import MyMap from "./components/MyMap";
import { css } from "@emotion/react";
import Page from "./pages/page.ts";
import { apiClient } from "./services/apiClient.ts";
import CompleteSubscriptionPage from "./pages/completeSubscriptionPage.tsx";
import Privacy from "./pages/privacy.tsx";
import Terms from "./pages/terms.tsx";
import Footer from "./components/Footer";
import ZulipPage from "./pages/zulip.tsx";
import HelpPage from "./pages/helpPage.tsx";
import LessonPlan from "./components/LessonPlan";
import { ComponentPreviews, useInitial } from "./dev";
import GettingStartedPage from "./pages/gettingStarted.tsx";
import Analytics from "./components/Analytics";
import Suggestions from "./components/Suggestions";
import Changelog from "./components/Changelog";
import { GoogleOAuthProvider } from "@react-oauth/google";
import InvitePage from "./pages/invitePage.tsx";


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

function Home() {
  const outlet = useOutlet();

  return (
    <>
      <LandingHeader></LandingHeader>
      {outlet || <Landing />}
    </>
  );
}

// Middleware to fetch new notifcations
const autoRevalidateMiddleware: Middleware = (useSWRNext) => {
  return (key, fetcher, config) => {
    const swr = useSWRNext(key, fetcher, config);

    const originalMutate = swr.mutate;

    // Override the mutate function
    swr.mutate = async (data, shouldRevalidate) => {
      const result = await originalMutate(data, shouldRevalidate);
      mutate(apiClient().allNotificationsList.name); // Always fetch new notifications
      return result;
    };

    return swr;
  };
};

const router = createBrowserRouter([
  {
    errorElement: <ErrorPage />,
    element: (
        <Analytics>
          <GoogleOAuthProvider clientId="858808532426-85clcmvdjil1kges81eaa7udk4sogqlf.apps.googleusercontent.com">
          <ThemeProvider theme={theme}>
        <SWRConfig
          value={{
            revalidateIfStale: false,
            revalidateOnMount: true,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            use: [autoRevalidateMiddleware],
          }}
        >
          <React.StrictMode>
            <CssBaseline />
            <SnackbarProvider>
              <UserContext>
                <DevSupport
                  ComponentPreviews={ComponentPreviews}
                  useInitialHook={useInitial}
                >
                  <>
                    <CssBaseline />
                    <Outlet />
                    {/*<ZulipPage />*/}
                  </>
                </DevSupport>
              </UserContext>
            </SnackbarProvider>
          </React.StrictMode>
        </SWRConfig>
      </ThemeProvider>
          </GoogleOAuthProvider>
        </Analytics>
    ),
    children: [
      {
        path: "/app",
        element: (
          <>
            <ProtectedRoute>
              <Header />
              <div
                id={"main"}
                css={css`
                  padding-left: 5px;
                  padding-right: 5px;
                  padding-bottom: 20px;
                  padding-top: 20px;
                `}
              >
                <Outlet />
              </div>
            </ProtectedRoute>
          </>
        ),
        children: [
          {
            path: "/app/news",
            element: (
              <Page title={"News"}>
                <News />
              </Page>
            ),
          },
          {
            path: "/app/news/:id",
            element: (
              <Page title={"Article"}>
                <ArticlePage />
              </Page>
            ),
          },
          // {
          //   path: "/app/community",
          //   element: (
          //     <Page title={"Community"}>
          //       <Community />
          //     </Page>
          //   ),
          // },

          {
            path: "/app/nations",
            element: (
              <Page title={"Nations"}>
                <Nations />
              </Page>
            ),
          },

          {
            path: "/app/nations/:id",
            element: (
              <Page title={"Nation"}>
                <NationPage />
              </Page>
            ),
          },

          {
            path: "/app/trade",
            element: (
              <Page title={"Trade"}>
                <TradePage />
              </Page>
            ),
          },

          {
            path: "/app/map",
            async lazy() {
              const { MapPage } = await import("./pages/mapPage");
              return {
                Component: () => {
                  return (
                      <Page title={"Map"}>
                        <MapPage />
                      </Page>
                  );
                },
              };
            }
          },

          {
            path: "/app/development",
            element: (
              <Page title={"Development"}>
                <DevelopmentPage />
              </Page>
            ),
          },

          {
            path: "/app/diplomacy",
            element: (
              <Page title={"Diplomacy"}>
                <Diplomacy />
              </Page>
            ),
          },

          {
            path: "/app/profile",
            element: (
              <Page title={"Profile"}>
                <ProfilePage />
              </Page>
            ),
          },
          {
            path: "/app/guide",
            element: (
              <Page title={"Guide"}>
                <HelpPage />
              </Page>
            ),
          },
          {
            path: "/app/getting_started",
            element: (
                <Page title={"Getting Started"}>
                  <GettingStartedPage />
                </Page>
            ),
          },
          {
            path: "/app/lesson_plan",
            element: (
              <Page title={"Lesson Plan"}>
                <LessonPlan />
              </Page>
            ),
          },

          {
            path: "/app/profile/:id",
            element: (
              <Page title={"Profile"}>
                <ProfilePage />
              </Page>
            ),
          },


          {
            path: "/app/suggestions",
            element: (
                <Page title={"Suggestions"}>
                  <Suggestions />
                </Page>
            ),
          },

          {
            path: "/app/changelog",
            element: (
                <Page title={"Changelog"}>
                  <Changelog />
                </Page>
            ),
          },
        ],
      },

      {
        path: "/",
        element: (
          <Page title={"Home"}>
            <Home />
            <Footer sx={{ mt: 5 }} />
          </Page>
        ),
        children: [
          {
            path: "/login",
            element: (
              <Page title={"Login"}>
                <SignInPage />
              </Page>
            ),
          },
          {
            path: "/register",
            element: (
              <Page title={"Register"}>
                <CompleteSubscriptionPage />
              </Page>
            ),
          },
          {
            path: "/invite/:code",
            element: (
                <Page title={"Invite"}>
                  <InvitePage />
                </Page>
            ),
          },
          // {
          //   path: "/signup",
          //   element: (
          //     <Page title={"Signup"}>
          //       <SignUpPage />
          //     </Page>
          //   ),
          // },
          {
            path: "/reset",
            element: (
              <Page title={"Reset Password"}>
                <ResetPage />
              </Page>
            ),
          },
          {
            path: "/confirm/:token",
            element: (
              <Page title={"Confirm Email"}>
                <ConfirmPage />
              </Page>
            ),
          },
          {
            path: "/about",
            element: (
              <Page title={"About"}>
                <About />
              </Page>
            ),
          },
          {
            path: "/privacy-policy",
            element: (
              <Page title={"Privacy"}>
                <Privacy />
              </Page>
            ),
          },
          {
            path: "/terms-of-service",
            element: (
              <Page title={"Terms"}>
                <Terms />
              </Page>
            ),
          },
          {
            path: "/contact",
            element: (
              <Page title={"Contact"}>
                <Contact />
              </Page>
            ),
          },
        ],
      },

      {
        path: "/admin/*",
        async lazy() {
          const { AdminPage } = await import("./pages/admin");
          return {
            Component: () => {
              return (
                <>
                  <ProtectedRoute>
                    <AdminPage />
                  </ProtectedRoute>
                </>
              );
            },
          };
        },
      },
    ],
  },
]);

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://4f596b610ad3fed6bd304ca6ed7b63e5@o372390.ingest.sentry.io/4505741721206784",
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      /*"localhost:3000",*/ "https:geostratapp.com",
    ],
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

root.render(<RouterProvider router={router} />);
