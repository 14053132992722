/** @jsxImportSource @emotion/react */
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import { css } from "@emotion/react";
import { Item } from "../../my-api-client";
import Cost from "./cost.tsx";

interface ItemInputProps {
  item: Item;
  points?: string;
}

export default function StoreContent({ item, points }: ItemInputProps) {
  const placements = [];
  item.isLand &&
    placements.push("/temp2/tile_05.png") &&
    placements.push("/temp2/tile_06.png") &&
    placements.push("/temp2/tile_10.png") &&
    placements.push("/temp2/tile_09.png") &&
    placements.push("/temp2/tile_03.png");
  item.isOcean &&
    placements.push("/temp2/tile_11.png") &&
    placements.push("/temp2/tile_12.png") &&
    placements.push("/temp2/tile_13.png") &&
    placements.push("/temp2/tile_14.png");
  item.isWell && placements.push("/temp2/tile_09.png");
  item.isOceanOil && placements.push("/temp2/tile_14.png");
  item.isFish && placements.push("/temp2/tile_13.png");
  item.isMineral && placements.push("/temp2/tile_10.png");
  item.isLumber && placements.push("/temp2/tile_06.png");
  item.isAg && placements.push("/temp2/tile_05.png");
  item.isRiver && placements.push("/temp2/tile_05_1.png") && placements.push("/temp2/tile_05_2.png") && placements.push("/temp2/tile_05_3.png") && placements.push("/temp2/tile_05_4.png");
  item.isCity &&
    placements.push("/temp2/tile_05.png") &&
    placements.push("/temp2/tile_03.png");
  item.isLandOil && placements.push("/temp2/tile_03.png");
  item.isShoreline && placements.push("/temp2/tile_12.png");

  // if (item.name.includes("Jet")) {
  //   placements.push("/temp2/tile_05.png") &&
  //   placements.push("/temp2/tile_06.png") &&
  //   placements.push("/temp2/tile_10.png") &&
  //   placements.push("/temp2/tile_09.png") &&
  //   placements.push("/temp2/tile_03.png") &&
  //   placements.push("/temp2/tile_11.png") &&
  //   placements.push("/temp2/tile_12.png") &&
  //   placements.push("/temp2/tile_13.png") &&
  //   placements.push("/temp2/tile_14.png") &&
  //   placements.push("/temp2/tile_09.png") &&
  //   placements.push("/temp2/tile_14.png") &&
  //   placements.push("/temp2/tile_13.png") &&
  //   placements.push("/temp2/tile_10.png") &&
  //   placements.push("/temp2/tile_06.png") &&
  //   placements.push("/temp2/tile_05_1.png") && placements.push("/temp2/tile_05_2.png") && placements.push("/temp2/tile_05_3.png") && placements.push("/temp2/tile_05_4.png") &&
  //   placements.push("/temp2/tile_03.png") &&
  //   placements.push("/temp2/tile_03.png") &&
  //   placements.push("/temp2/tile_12.png");
  // }

  return (
    <>
      <CardMedia
        component="img"
        height="64"
        width={64}
        image={item.image || ""}
        alt=""
        sx={{
          width: 64,
          marginTop: "16px",
          marginLeft: "16px",
        }}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {item.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {item.description}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          css={css`
            display: flex;
            align-items: center;
            margin-top: 10px;
          `}
        >
          Placement:
          {
            placements.length == 0 ? " Anywhere" : ""
          }
          {placements.map((tile, index) => (
            <img
              src={tile}
              width={24}
              height={24}
              key={index}
              css={css`
                margin-left: 4px;
                margin-right: 4px;
              `}
            />
          ))}
        </Typography>
        { (points == undefined ? item.points : points) ?
        <Typography
            variant="body2"
            color="text.secondary"
            css={css`
            display: flex;
            align-items: center;
            margin-top: 10px;
          `}
        >
          Power Points: {points == undefined ? item.points : points}
        </Typography> : <></>
        }
        <Cost item={item} costKey={"cost"} title="Development Cost" />
        <Cost item={item} costKey={"dailyCost"} title="Daily Cost" />
        {
          item.dice ? (<Typography
                variant="body2"
                color="text.secondary"
                css={css`
            display: flex;
            align-items: center;
            margin-top: 10px;
          `}>Strength: {item.dice}</Typography>) : <></>
        }
        {
          item.movementRange ? (<Typography
              variant="body2"
              color="text.secondary"
              css={css`
            display: flex;
            align-items: center;
            margin-top: 10px;
          `}>Movement Speed: {item.movementRange}</Typography>) : <></>
        }
      </CardContent>
    </>
  );
}
